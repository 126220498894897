import React, { useEffect, useRef, useState } from "react";

import estilo from "./styles/SobreMi.module.css";
import cv from "../pdf/Cv_2024.pdf";
import { DataContext } from "../context/Contexto";
import { useContext } from "react";
import foto from "../img/foto_ross.png";
import lista from "../json/listaTecnologias";

function SobreMi() {
  const elementoRef = useRef(null);

  const [activarAnimacion, setActivarAnimacion] = useState(false);

  useEffect(() => {
    const options = {
      root: null, // El área de observación es el viewport (ventana del navegador)
      rootMargin: "0px", // Margen alrededor del área de observación
      threshold: 0.5, // Porcentaje del elemento visible necesario para disparar la callback
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // El elemento está completamente en la pantalla
          console.log("Elemento visible");
          setActivarAnimacion(true);
          setTimeout(() => {
            setActivarAnimacion(false);
          }, 1000);
        }
      });
    }, options);

    if (elementoRef.current) {
      observer.observe(elementoRef.current);
    }

    // Limpia el observador cuando el componente se desmonta
    return () => {
      if (elementoRef.current) {
        observer.unobserve(elementoRef.current);
      }
    };
  }, []);

  //botones
  const { activarForm, setActivarForm } = useContext(DataContext);

  function formularioActivar() {
    setActivarForm(!activarForm);
  }

  return (
    <div className={estilo.sobre_mi} id="sobre" ref={elementoRef}>
      <div className={estilo.foto_botones}>
        <div className={estilo.foto}>
          <img src={foto} alt="foto-ross" loading="lazy" />
        </div>

        <div className={estilo.botones}>
          <div className={estilo.boton_2} onClick={formularioActivar}>
            <p>Contactar</p>
            <svg width="86" height="70" viewBox="0 0 86 70" fill="none">
              <path d="M86 18.1623V56.5385C86 60.1087 84.5843 63.5327 82.0642 66.0572C79.5442 68.5817 76.1263 70 72.5625 70H13.4375C9.87365 70 6.45577 68.5817 3.93575 66.0572C1.41573 63.5327 0 60.1087 0 56.5385V18.1623L41.6348 42.7054C42.0484 42.9498 42.5198 43.0787 43 43.0787C43.4802 43.0787 43.9516 42.9498 44.3652 42.7054L86 18.1623ZM72.5625 1.66668e-07C75.8689 -0.000520778 79.0594 1.22019 81.5237 3.4286C83.988 5.63701 85.553 8.67807 85.9194 11.97L43 37.2615L0.0806256 11.97C0.446976 8.67807 2.01199 5.63701 4.47627 3.4286C6.94055 1.22019 10.1311 -0.000520778 13.4375 1.66668e-07H72.5625Z" />
            </svg>
          </div>

          <a
            className={estilo.boton_1}
            href={cv}
            download="Bryan_Ticona_Valencia_CV.pdf"
          >
            <p>Descargar CV</p>
          </a>
        </div>
      </div>

      <div className={estilo.contenido}>
        <div className={estilo.texto_sobre_mi}>
          <h3>Sobre Mi</h3>
          <p>
            Soy un Desarrollador Web altamente comprometido con la mejora
            continua de mi trabajo y con un gran interés en mantenerme al día
            con las últimas tendencias y tecnologías en el campo del Desarrollo
            Web. Mi pasión por la creación y mejora de sitios web me ha llevado
            a dedicar tiempo y esfuerzo constante para perfeccionar mis
            habilidades y conocimientos
          </p>
        </div>

        <div className={estilo.flex_tecnologias}>
          <h4>Tecnologías</h4>

          <div className={estilo.lista_iconos}>
            {lista.map((e, i) => (
              <a
                key={i}
                href={e.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={estilo.icono}>{e.icono}</div>
              </a>
            ))}
          </div>
        </div>

        <div className={estilo.texto_cantidad}>
          <div className={estilo.texto_cantidad_contenido}>
            <h3>
              31<em>+</em>
            </h3>
            <p>Aplicaciones Web</p>
          </div>
          <div className={estilo.texto_cantidad_contenido}>
            <h3>
              9<em>+</em>
            </h3>
            <p>Aplicaciones Móviles</p>
          </div>
          <div className={estilo.texto_cantidad_contenido}>
            <h3>
              38<em>+</em>
            </h3>
            <p>Diseños Web</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SobreMi;
